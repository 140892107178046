@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;900&display=swap');
/* @font-face {
  font-family: "PlayFair";
  src: url('../public/fonts/playFair/PlayfairDisplay-Black.ttf');
  public: url('../public/fonts/playFair/PlayfairDisplay-Black.ttf') format('truetype');
} */
a {  
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}
* {
  box-sizing: border-box;
}
button{
  text-transform: capitalize !important;
}

body{
  background-image: url(webpack:///mini-css-extract-plugin//_next/static/media/bg.5355f7e7.png);
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
}
:root {
  --swiper-theme-color: #0f524e !important;
  --swiper-navigation-size: 25px !important;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.main_sec {
  padding-top: 3rem !important;
  padding-bottom: 1rem !important;
  height: auto !important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: #0f524e;
}

.custom-form-control{
  background-color: #1D1D1D !important;
  border-radius: 10px !important;
  width: 100%;
  height: 50px;
  outline: 0;
  border: 1px solid rgb(93, 93, 93) !important;
  margin: 15px auto 0 auto;
  display: block;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  padding: 1rem;
  font-size: 18px;
}

.themeBtnAlt {
  width:100%;
  background-color: transparent !important;
  color: #000 !important;
  opacity: 1;
  border: 1px solid #0f524e !important;
  border-radius: 5px !important;
  font-weight: 500;
  font-family: 'Playfair Display', serif !important;
  font-size: 16px !important;
  /* letter-spacing: 0.3px; */
}

.themeBtnAlt:hover {
  background-color: #0f524e !important;
  color: #fdd00c !important;
  opacity: 1;
  border: 1px solid #0f524e !important;
  border-radius: 5px !important;
}

.themeBtn {
  width:100%;
  background-color: #0f524e !important;
  color: #fdd00c !important;
  opacity: 1;
  border: 1px solid #0f524e !important;
  border-radius: 5px !important;
  font-weight: 500;
  font-family: 'Playfair Display', serif !important;
  font-size: 16px !important;
  /* letter-spacing: 0.3px; */
}

.themeBtn:hover {
  background-color: #fdd00c !important;
  color: #0f524e !important;
  opacity: 1;
  border: 1px solid #fdd00c !important;
  border-radius: 5px !important;
}

.overlay-box {
  background-color: rgba(0, 3, 0, 0.9);
  text-align: center;
  height: 100%;
  width: 100%;
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-self: center;
  min-height: 100%;
  justify-content: center;
  align-items: center;
}

.overlay-box {
  color: #ffffff99;
  letter-spacing: 1px;
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;  
  background-color: rgb(30, 30, 30);
  opacity: 0.99;
  background-size: 300px;
  color: #d2d2d2;
  letter-spacing: 1.5px;
}

.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none;
}

.slick-slide {
  padding: 0px;
}

.swiper-slide {
  background-size: cover;
}

.desktopMode{
  display:block !important;
}

.mobileMode{
  display: none !important;
}


.stickyPosition{
  position: sticky !important;
  top: 8.25% !important;
  padding: 15px !important;
  border-radius: 5px !important;
  background: #171919;
}


@media screen and (max-width:767px){
  .desktopMode{
    display:none !important;
  }
  .mobileMode{
    display: block !important;
  }
  .swiper-button-prev,
  .swiper-button-next{
    display:none !important;
  }
  .main_sec{
    padding-top: 1rem !important;
  }
  .stickyPosition{
    top: 6.5% !important;
    padding: 10px !important;
    border-radius: 1px !important;
  }
}
.Mui-error{
  color:#ff5500 !important;
  text-transform: capitalize;
  margin-left: 0;
  margin-right: 0;
}

.autoWidthSlider .swiper-slide {
  width: auto !important;
}

.products-wrapper {
  height: 100%;
  width: 90%;
  position: relative;
  display: block;
  text-align: left;
  margin: 0 auto;
}
.swiper-button-next {
  margin-top: 0px;
  position: absolute !important;
  top: 30%;
  right: 0px;
  width: 45px;
  height: 45px;
  transform: translateY(-50%);
  z-index: 999;
  background-color:#0f524e;
}
.swiper-button-prev {
  position: absolute !important;
  top: 30%;
  left: 0px;
  width: 45px;
  height: 45px;
  transform: translateY(-50%);
  margin-top: 0px;
  z-index: 999;
  background-color:#0f524e;

}

.footerBg{
  background-image: url(webpack:///mini-css-extract-plugin//_next/static/media/footerBg.05f10cf0.png);
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
  padding: 70px 0 0 0;
}
